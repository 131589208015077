import React, { useState } from "react";
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    Tab,
    Tabs,
    Typography,
    List,
    ListItem,
    ListItemText,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from "@mui/material";

// Function to convert a string to camel case
const toCamelCase = (str) => {
    return str.replace(/([A-Z])/g, " $1")  // Add space before uppercase letters
        .replace(/^./, (match) => match.toUpperCase()); // Capitalize first letter
};

const convertToArray = (obj) => {
    return Object.entries(obj).map(([key, value]) => ({ key: toCamelCase(key), value }));
};

const TabValueLabels = {
    '0': 'PROFILE',
    '1': 'OVERVIEW',
    '2': 'EMAIL',
    '3': 'PASSWORD',

}
const ProfilePage = ({ user }) => {

    const [tabValue, setTabValue] = useState(0);
    const allowedKeys = ["College Name", "course Name"];
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const userFormData = convertToArray(user.data);
    const userName = userFormData.filter(elm => elm.key === 'Name');
    // if (Array.isArray(user)) return <Typography>Loading...</Typography>;

    return (
        <Grid container spacing={2} p={1}>
            {/* {JSON.stringify(userFormData) ?? 'abc'} */}
            {/* Left Sidebar */}
            <Grid item xs={12} md={3}>
                <Card sx={{ p: 2, textAlign: "center" }}>
                    <Avatar
                        src="/profile.jpg"
                        sx={{ width: 100, height: 100, mx: "auto", mb: 2 }}
                    />
                    <Typography variant="h6">{userName[0].value}</Typography>
                    <Typography variant="body2" color="text.secondary">
                        Project Manager
                    </Typography>
                    <Box mt={2}>
                        <Typography variant="body2">Project Name: Test One</Typography>
                        <Typography variant="body2">Team Size: 5</Typography>

                    </Box>
                    <Button variant="contained" color="primary" disabled fullWidth sx={{ mt: 2 }}>
                        Verify
                    </Button>
                </Card>
                <Card sx={{ mt: 2, p: 2 }}>
                    <Typography variant="h6">About Me</Typography>
                    <List >
                        {userFormData.filter((item) => allowedKeys.includes(item.key)).map((row, index) => (
                            <ListItem key={index} sx={{ display: "flex", justifyContent: "space-between", py: 1 }}>
                                <ListItemText primary={<strong>{row.key}</strong>} secondary={row.value} />
                            </ListItem>
                        ))}
                    </List>
                </Card>
            </Grid>

            {/* Right Content */}
            <Grid item xs={12} md={9}>
                <Card>
                    <Tabs value={tabValue} onChange={handleChange} indicatorColor="primary"
                    sx={{
                        pb: 0,
                        borderBottom: "1px solid #ccc",
                        minHeight: "42px",
                        width: 'auto'
                      }}>
                        <Tab label="Profile" />
                        <Tab label="Overview" />
                        <Tab label="Emails" />
                        <Tab label="Password" />
                    </Tabs>
                    <CardContent>
                        {tabValue === 0 && (
                            <>

                                {userFormData.filter((item) => item.key !== 'Name').map((row, index) => (
                                    <ListItem key={index} sx={{ display: "flex", justifyContent: "space-between", py: 1 }}>
                                        <ListItemText primary={<strong>{row.key}</strong>} sx={{ fontWeight: "bold", fontSize: "100", flex: 1 }} />
                                        <ListItemText primary={row.value} sx={{ flex: 2 }} />
                                    </ListItem>
                                ))}
                            </>
                        )}
                        {tabValue !== 0 && (
                            <>
                                <Typography variant="h6" gutterBottom>
                                    { TabValueLabels[tabValue] }
                                </Typography>
                                <Table>
                                    <TableBody>
                                        {[
                                            ["Message", "updating soon..."]
                                        ].map(([label, value]) => (
                                            <TableRow key={label}>
                                                <TableCell sx={{ fontWeight: "bold" }}>{label}</TableCell>
                                                <TableCell>{value}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </>
                        )}
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default ProfilePage;
