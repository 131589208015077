import React, { useState } from "react";
import { Container, Grid, Card, CardContent, Typography, Button, Box, Modal } from "@mui/material";
import WebIcon from "@mui/icons-material/Web";
import CloudIcon from "@mui/icons-material/Cloud";
import AnalyticsIcon from '@mui/icons-material/Analytics';
import {RegisterButton} from "../custom-components/button";
import CheckIcon from "@mui/icons-material/Check";

const services = [
  {
    icon: <WebIcon sx={{ fontSize: 50, color:'rgb(0 166 244)' }} />,
    title: "Frontend - Intern",
    description: "Design and build interactive web applications with modern frontend technologies.",
    skills:['HTML, CSS, JavaScript','React.js, Angular','Redux, NgRx','API Integration: RESTful APIs', 'Testing: Jest', 'Version Control: Git, GitHub,']
  },
  {
    icon: <CloudIcon sx={{ fontSize: 50, color:'rgb(0 166 244)' }} />,
    title: "DevOps -  Intern",
    skills:['CI/CD: Jenkins, GitHub Actions', 'Cloud Platforms: AWS, Azure, GCP', 'Containerization: Docker','Scripting : Bash, Python', 'Version Control: Git, GitHub', 'Networking : Load Balancing, CDN'],
    description: "Automate deployments, manage cloud infrastructure, and ensure seamless CI/CD pipelines for smooth software delivery."
  },

  {
    icon: <AnalyticsIcon sx={{ fontSize: 50, color:'rgb(0 166 244)' }} />,
    title: "Data Science -  Intern",
    skills:['Programming: Python, SQL', 'Data Analysis: Pandas, NumPy','Big Data: Apache Spark, Hadoop', 'Data Visualization: Power BI', 'Databases: MySQL, PostgreSQL','Cloud: AWS SageMaker, Azure ML' ],
    description: " Data Science team analyzes complex datasets, develops machine learning models, and optimizes decision-making with AI-powered solutions"
  }
];

const ServicesSection = ({isMobile}) => {
  const [open, setOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  const handleOpen = (service) => {
    setSelectedService(service);
    setOpen(true);
  };

  return (
    <Box sx={{ backgroundColor: '#f3f3f5', px: isMobile?  '0px' : "5rem" }}>
      <Container sx={{ py: 3 }}>
        <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
          What we are looking for
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          As a growing startup, we strongly believe in the potential of fresh talent. We are actively looking for interns to join our development team, where they will gain hands-on experience and work on real-world projects.
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          This internship is a great opportunity for aspiring developers to sharpen their skills, work alongside experienced professionals, and contribute to exciting projects from day one.
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {services.map((service, index) => (
            <Grid item xs={12} sm={4} md={4} key={index}>
              <Card sx={{ px: 3, textAlign: "left", boxShadow: 3, height: "100%" }}>
                <CardContent>
                  {service.icon}
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    {service.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" gutterBottom >
                    {service.description}
                  </Typography>
                  {service.skills.map((benefit, index) => (
                            <Box key={index} display="flex" alignItems="center" sx={{ mb: 1 , mt:1}}>
                                <CheckIcon color="primary" sx={{ mr: 1 }} />
                                <Typography variant="body2">{benefit}</Typography>
                            </Box>
                  ))}
                  <Box sx={{ mt: 2, display: "flex", gap: 2, flexWrap: "wrap" }}>
                    <RegisterButton text="Register" />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default ServicesSection;