import React from "react";
import { Box, Card, CardContent, Typography, Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {RegisterButton} from "../custom-components/button";

const EligibilityDetails = () => {
    return (
        <Card sx={{ mx: 13, mt: 4, px: 3, py: 2, boxShadow: 3 }}> {/* Added py: 5 for padding around the card */}
            <CardContent sx={{ maxWidth: 'auto' }}>

                {/* Header Section with Text and Button */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <Box>
                        <Typography variant="h5" fontWeight="bold" color="rgb(37, 211, 102)">
                            Earn up to ₹5,000
                            <Typography variant="body2" component="span"> /month, from 3rd month onwards</Typography>
                        </Typography>
                    </Box>

                    {/* Align Button to the Right */}
                    <Box sx={{ ml: 'auto' }}> {/* Ensures the button is right-aligned */}
                        <RegisterButton text="Register" />
                    </Box>
                </Box>

                {/* Eligibility Criteria Section */}
                <Typography variant="h6"
                    mt={2}
                >
                    Stipend eligibility criteria
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon><CheckCircleIcon sx={{ color: "rgb(37, 211, 102)" }} /></ListItemIcon>
                        <ListItemText primary="Interns must register with a ₹1,000 fee, fully refunded as a stipend upon joining. " />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><CheckCircleIcon sx={{ color: "rgb(37, 211, 102)" }} /></ListItemIcon>
                        <ListItemText primary="Must complete the first two months of the internship successfully." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><CheckCircleIcon sx={{ color: "rgb(37, 211, 102)" }} /></ListItemIcon>
                        <ListItemText primary="Best performers will be shortlisted based on technical skills, commitment, and contributions to the projects." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><CheckCircleIcon sx={{ color: "rgb(37, 211, 102)" }} /></ListItemIcon>
                        <ListItemText primary="Must maintain consistent participation in team activities, meetings, and project milestones." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><CheckCircleIcon sx={{ color: "rgb(37, 211, 102)" }} /></ListItemIcon>
                        <ListItemText primary="Should demonstrate proactive learning, problem-solving abilities, and teamwork throughout the internship." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><CheckCircleIcon sx={{ color: "rgb(37, 211, 102)" }} /></ListItemIcon>
                        <ListItemText primary="Stipend eligibility will be determined through regular code reviews, feedback sessions, and performance assessments." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><CheckCircleIcon sx={{ color: "rgb(37, 211, 102)" }} /></ListItemIcon>
                        <ListItemText primary="Only selected interns will be eligible for the stipend." />
                    </ListItem>
                </List>

                <Typography color="text.primary" mt={2}>
                    This structure ensures accountability while providing interns with a valuable learning experience and growth opportunities.
                </Typography>

            </CardContent>
        </Card>

    );
};

export default EligibilityDetails;
