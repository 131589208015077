// const API_BASE_URL = "http://localhost:9101/api/v1/website/auth";
// const API_USER_URL = "http://localhost:9101/api/v1/website/user";

const API_BASE_URL = `${process.env.REACT_APP_API_URL}/api/v1/website/auth`;
const API_USER_URL = `${process.env.REACT_APP_API_URL}/api/v1/website/user`; 

const API_ENDPOINTS = {
  REGISTER: `${API_BASE_URL}/signup`,
  LOGIN: `${API_BASE_URL}/login`,
  USER_DETAILS_BY_EMAIL: `${API_BASE_URL}/getUserByEmail`,
  UPDATE_PROFILE: `${API_BASE_URL}/user/update`,
  GET_PROFILE: `${API_USER_URL}/getProfile`,
};

export default API_ENDPOINTS;
