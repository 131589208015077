import React from "react";
import { Card, CardContent, Typography, Button, Grid, Box, Container } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import {RegisterButton} from "../custom-components/button";
import { Navigate, useNavigate } from "react-router-dom";

const ProductSectionCard = ({isMobile}) => {

  const navigate = useNavigate();
  return (
    <Box sx={{ bgcolor: "#f3f3f5"}}>
    <Container>  
    <Card sx={{ backgroundColor: "#f3f3f5", borderRadius: 2, boxShadow: 0, pt:5, pb:5 }}>
      <CardContent sx={{ px: isMobile ? '0px' : '3rem' }}>
        <Grid container spacing={4} alignItems="center">
          {/* Left Section */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" fontWeight="bold">
                Work From Home Internships
            </Typography>
            <Typography variant="body1" color="text" mt={1} mr={8}>
            Learn, develop, and grow—all from wherever you are! <br/>
            Join our remote internship program 
            to apply your skills in authentic development environments and build the expertise needed to thrive in today's dynamic tech industry.
            </Typography>
            {/* <Typography variant="h5" fontWeight="bold" color="rgb(37, 211, 102)" mt={1}>
            Stipend upto ₹ 5,000 <Typography variant="body2" component="span"> /month,</Typography>
            </Typography> */}
            {/* <Typography variant="body2" color="textSecondary" mt={1}>
            from 3rd month onwards.
            </Typography>
            <Typography variant="body2" color="textSecondary" mt={1}>
             For eligible candidates.
            </Typography> */}
            
            <Box mt={2}>
             {isMobile ? <></> : <RegisterButton text="register" ></RegisterButton> } 
            {/* <RegisterButton text="register"></RegisterButton> */}
              {/* <Button variant="contained" size="small" sx={{bgcolor:"rgb(37, 211, 102)"}} onClick={() => navigate('/eligibility')}>
                Check Eligibility
              </Button> */}
            </Box>
           
            {/* Buttons */}
            {/* <Box mt={2}>
              <Button variant="contained" sx={{ backgroundColor: "#7356BF", color: "#fff", mr: 2 }}>
                Buy now
              </Button>
              <Button variant="text" color="primary">
                Learn more →
              </Button>
            </Box> */}
          </Grid>

          {/* Right Section */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" fontWeight="bold">
            Adhere To Industry Best Practices:
            </Typography>
            <Box mt={1}>
              {[
                "Agile Methodology for flexibility and continuous improvement.",
                "Microsoft Ecosystem (Teams, Outlook, and more) for seamless communication.",
                "JIRA for Task Management to track and execute projects efficiently.",
                "Collaborative Work Culture fostering teamwork and innovation",
                "Working with advanced and emerging technologies stack."
              ].map((text, index) => (
                <Box key={index} display="flex" alignItems="center" gap={1} mt={1}>
                  <CheckIcon color="primary" sx={{ fontSize: 18 }} />
                  <Typography variant="body2">{text}</Typography>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
      {/* TODO: to update later as per view */}
      {/* {isMobile ? <RegisterButton text="register" ></RegisterButton> : <></>  }  */}
    </Card>
    </Container>
    </Box>
  );
};

export default ProductSectionCard;
