import React, { useEffect, useState } from 'react';
import { Typography, Box, Tabs, Tab, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";

const TabContent = ({ isMobile }) => {

    const [tabValue, setTabValue] = useState(0);
    const [benefits, setBenefits] = useState([]);

     useEffect(() => {
        fetch("/MockData/registerbenefits.json")
          .then((response) => response.json())
          .then((data) => {
            if (tabValue === 0) {
              setBenefits(data.freeRegistration);
            } else if (tabValue === 1) {
              setBenefits(data.paidRegistration);
            } else {
              setBenefits(data.stipendInterns);
            }
          })
          .catch((error) => console.error("Error loading benefits:", error));
      }, [tabValue]);
    
      const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
      };

      return (
        <>
        {/* Chrome-Like Tabs */}
        <Tabs
              value={tabValue}
              onChange={handleTabChange}
              variant="scrollable" // Makes tabs scrollable when overflowed
              scrollButtons="auto" // Auto shows scroll buttons when needed
              allowScrollButtonsMobile // Enables scrolling on mobile
              sx={{
                pb: 0,
                pt: 1,
                borderBottom: "1px solid #ccc",
                minHeight: "42px",
                width: 'auto',
                "& .MuiTabs-scrollButtons": {
                  width: isMobile ? '1rem' : 0 
                }
              }}
              TabIndicatorProps={{ style: { backgroundColor: "#1976d2", height: "2px" } }} // Blue underline
            >
              <Tab
                label="Free Registration"
                sx={{
                  textTransform: "none",
                  fontWeight: tabValue === 0 ? "bold" : "normal",
                  color: tabValue === 0 ? "#1976d2" : "inherit",
                  borderRadius: "10px 10px 0 0",
                  backgroundColor: tabValue === 0 ? "#e0ebf9" : "transparent",
                  padding: "10px 20px",
                  minHeight: "40px"
                }}
              />
              <Tab
                label="Paid Registration"
                sx={{
                  textTransform: "none",
                  fontWeight: tabValue === 1 ? "bold" : "normal",
                  color: tabValue === 1 ? "#1976d2" : "inherit",
                  borderRadius: "10px 10px 0 0",
                  backgroundColor: tabValue === 1 ? "#e0ebf9" : "transparent",
                  padding: "10px 20px",
                  minHeight: "40px"
                }}
              />
              <Tab
                label="Stipend Eligibility"
                sx={{
                  textTransform: "none",
                  fontWeight: tabValue === 2 ? "bold" : "normal",
                  color: tabValue === 2 ? "#1976d2" : "inherit",
                  borderRadius: "10px 10px 0 0",
                  backgroundColor: tabValue === 2 ? "#e0ebf9" : "transparent",
                  padding: "10px 20px",
                  minHeight: "40px"
                }}
              />
            </Tabs>
            <Box sx={{ minHeight: isMobile ? 'auto' : 450, display: "flex", flexDirection:'column' }}>
            <Box sx={{mt:2}}>
              {tabValue === 2 && 
              (
                <Typography variant="h5" fontWeight="bold" color="rgb(37, 211, 102)">
                  Earn up to ₹3,000
                  <Typography variant="body2" component="span"> /month, from 3rd month onwards</Typography>
                </Typography>
              )}
              </Box>
              {tabValue === 0 && (
                <List>
                  {benefits.map((benefit) => (
                    <ListItem key={benefit.id}>
                      <ListItemIcon sx={{
                        minWidth: isMobile ? "30px" : "56px",
                        color: "rgb(37, 211, 102)"
                      }}>
                        <CheckCircle />
                      </ListItemIcon>
                      <ListItemText primary={benefit.text}
                        primaryTypographyProps={{
                          sx: { fontSize: isMobile ? "0.9rem" : "1rem" }
                        }} />
                    </ListItem>
                  ))}
                </List>
              )}
              {tabValue === 1 && (
                <List>
                  {benefits.map((benefit) => (
                    <ListItem key={benefit.id}>
                      <ListItemIcon sx={{
                        minWidth: isMobile ? "30px" : "56px",
                        color:"rgb(37, 211, 102)"
                      }}>
                        <CheckCircle />
                      </ListItemIcon>
                      <ListItemText primary={benefit.text}
                        primaryTypographyProps={{
                          sx: { fontSize: isMobile ? "0.9rem" : "1rem" }
                        }} />
                    </ListItem>
                  ))}
                </List>
              )}
              {tabValue === 2 && (
                // <EligibilityDetails/>

                <List>
                  {benefits.map((benefit) => (
                    <ListItem key={benefit.id}>
                      <ListItemIcon sx={{
                        minWidth: isMobile ? "30px" : "56px",
                        color:"rgb(37, 211, 102)"
                      }}>
                        <CheckCircle />
                      </ListItemIcon>
                      <ListItemText primary={benefit.text}
                        primaryTypographyProps={{
                          sx: { fontSize: isMobile ? "0.9rem" : "1rem" }
                        }} />
                    </ListItem>
                  ))}
                </List>
              )}
            </Box>
        </>
      );
} 

export default TabContent;