import React, { useEffect, useMemo, useState } from "react";
import {Box, CircularProgress, Typography } from "@mui/material";
import UserDashboardComponent from "./userContainer";
import ProfilePage from "./profile";
import API_ENDPOINTS from "../config/appConfig";

const UserDetails = () => {

    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const userToken = localStorage.getItem("internToken"); // Get email from storage

    useEffect(() => {
        if (!userToken) return;
        const fetchUserData = async () => {
            try {
                const response = await fetch(`${API_ENDPOINTS.GET_PROFILE}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userToken}`, // Pass token here
                      },
                });
                if (response.ok) {
                    const data = await response.json();
                    setUser(data);
                    //   convertToArray(user);
                } else {
                    console.error("Failed to fetch user data");
                }
            } catch (error) {
                setError(error.message);
                console.error("Error fetching user data:", error);
            } finally {
                setLoading(false);
            }
        };

        if (userToken) fetchUserData();
    }, [userToken]);

    if (!user) return <Typography>Loading...</Typography>;
    // useEffect(() => {
    //     if (formData.username) {
    //         setUserDetailsList(
    //             (prevList) => {
    //                 const updatedList = [prevList, ...formData];
    //                 return updatedList;
    //             })
    //     }
    // }, [formData]); // Runs when formData changes

    // const [userDetailsList, setUserDetailsList] = useState([...MockUserDetailsList, formData]);

    return (
        <Box sx={{ display: "flex" }}>
            {/* Left Sidebar Drawer */}
            <UserDashboardComponent />
            {/* Main Content */}
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                {loading ? (
                    <CircularProgress /> // Show loader while fetching data
                ) : error ? (
                    <Typography color="error">{error}</Typography> // Show error if API fails
                ) : (
                    <ProfilePage user={user} /> // Pass user data to ProfilePage
                )}
            </Box>
        </Box>
    );
};

export default UserDetails;
