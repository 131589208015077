import React, { useState } from "react";
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Box, Toolbar, IconButton, Divider } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";

const drawerWidth = 240;
const collapsedWidth = 60;

const UserDashboardComponent = () => {
    const [open, setOpen] = useState(false);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    return (
        <Box sx={{ display: "flex" }}>
            {/* Toggle Button */}
            {/* <IconButton
                onClick={toggleDrawer}
                sx={{
                    position: "absolute",
                    left: open ? drawerWidth + 16 : collapsedWidth + 16,
                    top: 16,
                    transition: "left 0.3s",
                    zIndex: 1300,
                }}
            >
                <MenuIcon />
            </IconButton> */}

            {/* Mini Variant Drawer */}
            <Drawer
                variant="permanent"
                open={open}
                sx={{
                    width: open ? drawerWidth : collapsedWidth,
                    flexShrink: 0,
                    transition: "width 0.3s",
                    "& .MuiDrawer-paper": {
                        width: open ? drawerWidth : collapsedWidth,
                        boxSizing: "border-box",
                        overflowX: "hidden",
                        mb:'64px',
                        mt: '64px', // Below Header
                        transition: "width 0.3s",
                        "& .MuiToolbar-root": {
                            display: 'none'
                        }
                    },

                }}
            >
                <Toolbar />
                {/* MENU BUTTON INSIDE DRAWER */}
                <IconButton onClick={() => setOpen(!open)} sx={{ marginLeft: "10px", mb: 1, ":hover":'none' }}>
                    <MenuIcon sx={{ color:'rgb(37, 211, 102)'}} />
                </IconButton>
                <Divider />
                <List>
                    {/* <ListItem button onClick={toggleDrawer}>
                        <ListItemIcon>
                            <DashboardIcon />
                        </ListItemIcon>
                        {open && <ListItemText primary="Dashboard" />}
                    </ListItem> */}
                    <ListItem button onClick={toggleDrawer}>
                        <ListItemIcon>
                            <AccountCircleIcon sx={{ color:'rgb(37, 211, 102)'}} />
                        </ListItemIcon>
                        {open && <ListItemText primary="Profile" />}
                    </ListItem>
                    {/* <ListItem button onClick={toggleDrawer}>
                        <ListItemIcon>
                            <SettingsIcon />
                        </ListItemIcon>
                        {open && <ListItemText primary="Settings" />}
                    </ListItem> */}
                </List>
            </Drawer>
        </Box>
    );
};

export default UserDashboardComponent;
