import React, { useEffect, useState } from "react";
import { Container, Grid, Paper, Typography, TextField, MenuItem, Box, Button, CircularProgress, IconButton, InputAdornment } from "@mui/material";
import { useNavigate } from "react-router-dom";
import API_ENDPOINTS from "../../config/appConfig";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CryptoJS from "crypto-js";
import EligibilityDetails from "../../web-home/eligibility";
import TabContent from "./tabContent";

const topicList = [
  { id: 1, name: "Full Stack" },
  { id: 2, name: "Frontend" },
  { id: 3, name: "DevOps" },
  { id: 4, name: "Data Science" }
]

const courseYears = ["First Year", "Second Year", "Third Year", "Fourth Year"];

const encryptPassword = (password) => {
  return CryptoJS.AES.encrypt(password, process.env.REACT_APP_CRYPTO_JS_KEY).toString();
};

const RegistrationForm = ({ isMobile }) => {

  const [loading, setLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNo: "",
    internshipTopic: "",
    collegeName: "",
    password: "",
    courseName: "",
    courseYear: ""
    // courseAndYear: "",
  });

  const [errors, setErrors] = useState({
    email: "",
    mobileNo: "",
  });

  const handleTogglePassword = () => {
    setShowPassword((prev) => { return !prev });
  };

  const validateEmail = (email) => {
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
  };

  const validateMobileNo = (phone) => {
    return /^(\+91[-\s]?)?[6-9]\d{9}$/.test(phone);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Validate fields
    if (name === "email") {
      setErrors((prev) => ({
        ...prev,
        email: validateEmail(value) ? "" : "Invalid email format",
      }));
    }

    if (name === "mobileNo") {
      setErrors((prev) => ({
        ...prev,
        mobileNo: validateMobileNo(value) ? "" : "Enter a valid Indian phone number (e.g., +91XXXXXXXXXX or XXXXXXXXXX)",
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const encryptedPass = encryptPassword(formData.password);
    const updatedFormData = {
      ...formData,
      password: encryptedPass
    }
    // Simulating API request
    // alert(JSON.stringify(updatedFormData));
    try {
      setLoading(true);
      const response = await fetch(`${API_ENDPOINTS.REGISTER}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(updatedFormData),
      });

      const regResObj = await response.json();
      if (response.ok && regResObj?.status?.code === 200) {
        setLoading(false);
        alert(regResObj?.status?.message);
        // navigate("/profile", { state: formData });
        {/* Global Alert Component */ }
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          mobileNo: "",
          internshipTopic: "",
          collegeName: "",
          password: "",
          courseName: "",
          courseYear: ""
        });
      } else {
        setLoading(false);
        alert(regResObj?.status?.message);
        <commonAlert
          message='Registration Failed! Try again.'
          severity='error'
          open={true}
          onClose={() => setAlertOpen(false)}
        />
      }
    } catch (error) {
      setLoading(false);
      alert("Something went wrong!");
    }
  };

  return (
    <Box sx={{ px: isMobile ? 1 : 10, py: isMobile ? 2 : 1 }}>
      <Container maxWidth={isMobile ? "xs" : "lg"} sx={{ display: "flex", gap: 3, width: "100%" }}>
        <Grid container gap={1}>
          <Grid item xs={12} md={8} >
            <Typography variant="body2" sx={{ color: "rgb(240 96 34)", textAlign: isMobile ? "center" : "none" }}>
              To ensure genuine participation and avoid spam inquiries, ₹1000 registration fee is applicable.
            </Typography>
            {/* left section tab content */}
            <TabContent/>
          </Grid>
          <Paper sx={{ p: 2, flex: 1 }}>
            <Typography variant="h6" fontWeight="bold" gutterBottom align="left">
              Registration Form
            </Typography>
            <Box component="form" onSubmit={handleSubmit} autoComplete="off" sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 1 }}>
              <TextField
                select
                label="Internship Topic"
                name="internshipTopic"
                value={formData.internshipTopic}
                onChange={handleChange}
                fullWidth
                required
                size="small"
              >
                {topicList.map((option) => (
                  <MenuItem key={option?.id} value={option?.id}>
                    {option?.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField label="First Name"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                fullWidth
                required
                size="small"
                autoComplete="off" />

              <TextField label="Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                fullWidth
                required
                size="small"
                autoComplete="off" />

              <TextField
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                required
                size="small"
                autoComplete="off"
                error={!!errors.email}
                helperText={errors.email}
              />
              <TextField
                label="Phone Number"
                name="mobileNo"
                value={formData.mobileNo}
                onChange={handleChange}
                fullWidth
                required
                size="small"
                autoComplete="off"
                error={!!errors.mobileNo}
                helperText={errors.mobileNo}
                sx={{ "& .MuiInputBase-root": { pr: 0 } }}
              />
              {/* <   InputProps={{
              //     endAdornment: (
              //       <OTPVerificationPopup mobNo={formData.mobileNo} ></OTPVerificationPopup>
              //     )
              //   }}
              // /> */}
              <TextField label="Password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                value={formData.password}
                onChange={handleChange}
                fullWidth
                required
                size="small"
                autoComplete="off"
                mt={0}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleTogglePassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              {/* <TextField
                label="Password"
                fullWidth
                
                value={formData.password}
                onChange={handleChange}
                required
                size="small"
                autoComplete="off"
                mt={0}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleTogglePassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              /> */}

              <TextField label="College Name"
                name="collegeName"
                value={formData.collegeName}
                onChange={handleChange}
                fullWidth
                required
                size="small"
                autoComplete="off"
                mt={0} />

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Course Name"
                    name="courseName"
                    value={formData.courseName}
                    onChange={handleChange}
                    size="small"
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    select
                    label="Year"
                    name="courseYear"
                    value={formData.courseYear}
                    onChange={handleChange}
                    size="small"
                    required
                  >
                    {courseYears.map((courseYear) => (
                      <MenuItem key={courseYear} value={courseYear}>
                        {courseYear}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              <Button
                type="submit"
                variant="contained"
                disabled={loading || !!errors.email || !!errors.mobileNo}
                sx={{ display: "flex", alignItems: "center", gap: 1,  backgroundColor:"rgb(37, 211, 102)", 
                  "&:hover":{
                     backgroundColor:"rgb(37, 211, 102)"
                  }
                }}
              >
                {loading ? <CircularProgress size={20} /> : "Submit"}
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Container>
    </Box>
  );
};

export default RegistrationForm;
