import HomeInfoSection from './homeInfo';
import ProductSectionCard from './productPage';
import ServicesSection from './services';
import WhatWeDo from './whatwedo';

export default function WebIndex({isMobile, isLarge}){
    return (
        <>
        <HomeInfoSection isMobile={isMobile} isLgScreen={isLarge}/>
        <ProductSectionCard isMobile={isMobile} isLgScreen={isLarge}/>
        <WhatWeDo isMobile={isMobile} isLgScreen={isLarge}/>
        <ServicesSection isMobile={isMobile} isLgScreen={isLarge}/>
        </>
    );
}