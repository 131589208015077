import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import WebIndex from './web-home/web-index';
import RegistrationForm from './components/register/registerForm';
import Login from './components/login/login';
import AppHeader from './components/header/header';
import EligibilityDetails from './web-home/eligibility';
import Footer from './components/footer/footer';
import { Box, useMediaQuery } from "@mui/material";
import UserDetails from './web-home/userDetails';

function App() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isLargeScreen = useMediaQuery("md");
  return (
    <>
     <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh", // Ensures the full viewport height
      }}
    >
      <Box sx={{ flexGrow: 1}}>
        <Router>
          <AppHeader isMobile={isMobile} isLgScreen={isLargeScreen} />
          <Routes>
            <Route path="/" element={<WebIndex isMobile={isMobile} isLarge={isLargeScreen} />} />
            <Route path='/register' element={<RegistrationForm isMobile={isMobile} />} />
            <Route path='/login' element={<Login isMobile={isMobile}/>} />
            <Route path='/eligibility' element={<EligibilityDetails />} />
            <Route path='/profile' element={<UserDetails isMobile={isMobile}/>} />

            {/* Parent Route: Home */}
            {/* <Route path="/dashboard" element={<RootContainer />}> */}
              {/* Child Routes */}
              {/* <Route index element={<Home />} /> Default child */}
              {/* <Route path="profile" element={<Profile />} />
            <Route path="tech-stack" element={<div>Tech Stack</div>} />
            <Route path="work" element={<EducationDetails />} />
            <Route path="about" element={<div>Profile -Tech Stack</div>} />
            <Route path="register" element={<Register />} /> */}
            {/* </Route> */}
            {/* <Route path="/dashboard" element={<RootContainer/>} /> */}
          </Routes>
        </Router>
      </Box>
      <Footer />
      </Box>
    </>)
}

export default App;
