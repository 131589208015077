import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { RegisterButton } from "../../custom-components/button";
import { useLocation, Link, useNavigate } from "react-router-dom";

export default function AppHeader({isMobile}) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const navigate = useNavigate();
  const handleLogoClick = () => navigate('/');
  const location = useLocation();

  const handleMenuClick = (path) => {
    handleMenuClose();
    navigate(`${path}`);
  }

  return (
    <AppBar
      position="sticky"
      className="MuiPaper-root MuiPaper-elevation MuiPaper-elevation4 MuiAppBar-positionFixed mui-fixed"
      sx={{ backgroundColor: "#f8f9fa", color: "#000", boxShadow: 4 }}
      style= {{ "--Paper-shadow": "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);", "backgroundColor": "rgb(255 255 255/1", "padding": isMobile ? "0 1rem 0 1rem" :"0 5rem 0 5rem" }}
    >

      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        {/* Logo / Brand Name */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img
            src="/internsit_solutions_final.png" // Change this to your logo's path
            alt="InternsIt Logo"
            style={{ padding:3, height: "auto", width:150, cursor: 'pointer' }} // Adjust size as needed
            onClick={handleLogoClick}
          />
          {/* <Typography
          variant="h6"
          className="MuiTypography-root"
          sx={{ flexGrow: 1, fontWeight: "bold", color: "#000" }}
        >
          Ditto
        </Typography> */}

        </Box>

        {/* Desktop Navigation */}
        <Box sx={{ display: { xs: "none", md: "flex" }, gap: 2 }}>
          {/* <Button color="inherit" className="MuiButton-root">
            Health Insurance
          </Button>
          <Button color="inherit" className="MuiButton-root">
            Life Insurance
          </Button>
          <Button color="inherit" className="MuiButton-root">
            Claims
          </Button> */}
          {/* <Button color="inherit" className="MuiButton-root">
            Careers
          </Button> */}
          {/* <Button
            variant="contained"
            className="MuiButton-root MuiButton-contained"
            sx={{
              backgroundColor: "#007bff",
              color: "#fff",
              "&:hover": { backgroundColor: "#0056b3" },
            }}
          >
            Register
          </Button> */}

          <div>
            {location.pathname === "/profile" ? (
              <RegisterButton text="logout" />
            ) : location.pathname === "/login" ? (
              <RegisterButton text="register" />
            ) : location.pathname === "/register" ? (
              <RegisterButton text="login" />
            ) : (
              <>
                <RegisterButton text="login" />  &nbsp;&nbsp;
                <RegisterButton text="register" />
              </>
            )}
          </div>

        </Box>

        {/* Mobile Menu Button */}
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          className="MuiIconButton-root"
          sx={{ display: { xs: "block", md: "none" } }}
          onClick={handleMenuOpen}
        >
          <MenuIcon />
        </IconButton>

        {/* Mobile Dropdown Menu */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          className="MuiMenu-root"
        >
           {location.pathname === "/profile" && (
              <MenuItem onClick={() => handleMenuClick('logout')}>Logout</MenuItem>
            )}
            { location.pathname === "/login" && (
              <MenuItem onClick={() => handleMenuClick('register')}>Register</MenuItem>
            )}
            { location.pathname === "/register" && (
               <MenuItem onClick={() => handleMenuClick('login')}>Login</MenuItem>
            )}
            {
              location.pathname === "/" && 
             [ <MenuItem key="login" onClick={() => handleMenuClick('login')}>Login</MenuItem>,
                <MenuItem key="register" onClick={() => handleMenuClick('register')}>Register</MenuItem>
              ]}
          {/* <MenuItem onClick={handleLoginClick}>Login</MenuItem>
          <MenuItem onClick={handleLoginClick}>Login</MenuItem>
          <MenuItem onClick={handleLoginClick}>Login</MenuItem>  */}

        </Menu>
      </Toolbar>
    </AppBar>
  );

}