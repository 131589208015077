import React, { useState } from "react";
import { TextField, Button, Box, Typography, Container, Paper, Alert, IconButton  } from "@mui/material";
import { useNavigate } from "react-router-dom";
import API_ENDPOINTS from "../../config/appConfig";
// import {CommonAlert} from '../../custom-components/alert';
import CloseIcon from "@mui/icons-material/Close";
import CryptoJS from "crypto-js";

const encryptPassword = (password) => {
  return CryptoJS.AES.encrypt(password, process.env.REACT_APP_CRYPTO_JS_KEY).toString();
};


const Login = ({isMobile}) => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [error, setError] = useState("");
  
  const navigate = useNavigate(); // Hook for navigation

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

   const handleSubmit = async (event) => {
    try {
      if (!formData || !formData.password) {
        throw new Error("Password is missing in formData.");
      }

    event.preventDefault();
    setError('');
    const encryptedPass = encryptPassword(formData.password);
    const encryptedFormData = {
      ...formData,
      password: encryptedPass
    }


    try {
      const response = await fetch(`${API_ENDPOINTS.LOGIN}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(encryptedFormData),
      });

      const resObj = await response.json();

      if (response.ok && resObj?.data?.token && resObj?.status?.code === 200) {
        localStorage.setItem("internToken", resObj?.data?.token); // Store email
        // Redirect to profile page
       navigate("/profile");
      } else {
        setError(resObj?.status?.message || "Invalid login credentials");
      }
    } catch (error) {
      setError("Server error. Please try again later.");
    }
  }
    catch (err) {
      console.error("Login Error:", err.message);
    }
  
  };

  return (
    <Container 
    maxWidth={ isMobile ? 'md' : 'xs'}
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "calc(90vh - 50px)",
    }}
    >
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Paper sx={{ p: isMobile ? 2 : 3, flex: 1, boxShadow: 3, borderRadius: 2 , m: isMobile ? 2: 'auto' }}>
          <Typography variant="h5" align="center" gutterBottom sx={{ p:0}} >
            Login
          </Typography>
          {error &&  <Alert severity="error"
           action={
            <IconButton size="small" onClick={() => setError(null)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          }>{error}</Alert>}
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Username"
              name="username"
              variant="outlined"
              margin="normal"
              value={formData.username}
              onChange={handleChange}
              required
              size="small"
            />
            <TextField
              fullWidth
              label="Password"
              name="password"
              type="password"
              variant="outlined"
              margin="normal"
              value={formData.password}
              onChange={handleChange}
              required
              size="small"
              sx={{ mt: isMobile ? 0 : 'auto' }}
            />
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{ mt: isMobile ? 0 : 2 }}
            >
              Login
            </Button>
          </form>
        </Paper>
      </Box>
    </Container>
  );
};

export default Login;
