import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { purple } from '@mui/material/colors';
import TNCModal from "./tncmodal";
import { useNavigate } from "react-router-dom";
import OTPVerificationModal from "../components/otpforms/registerotp";

const BootstrapButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  backgroundColor: '#0063cc',
  borderColor: '#0063cc',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&:hover': {
    backgroundColor: '#0069d9',
    borderColor: '#0062cc',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#0062cc',
    borderColor: '#005cbf',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
});

// const registerBtnColor = "rgb(37, 211, 102)";
// const registerBtnColor = "rgb(25 118 210)";rgb(0 166 244)
const registerBtnColor = "rgb(0 166 244)";

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: registerBtnColor,
  '&:hover': {
    backgroundColor: registerBtnColor,
  },
}));

export function RegisterButton({ text }) {

  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handelLogout = () => {
    localStorage.removeItem("internToken");
    navigate('/');
  }

  if(text === 'logout'){
    return <ColorButton size="small" variant="contained" onClick={handelLogout}>{text}</ColorButton>;
  }
  return (
    <>
      <ColorButton size="small" variant="contained" onClick={() => navigate(`/${text}`)}>{text}</ColorButton>
      {/* <ColorButton size="small" variant="contained" onClick={() => setOpen(true)}>{text}</ColorButton> */}
      {/* <TNCModal modalOpen={open} modalOnClose={() => setOpen(false)} /> */}
      {/* <OTPVerificationModal open={open} onClose={() => setOpen(false)}/> */}
    </>
  );
}

const popupColor = '#1976d2';
const popupBgColor = 'rgba(25, 118, 210, 0.04)'
const PopupButtonTheme = styled(Button)(({ theme }) => ({
  color: popupColor,
  backgroundColor: 'transparent',
  width: "120px", // Prevent full width expansion
  alignSelf: "flex-start",
        minWidth: "auto",
        padding: 0,
        fontSize: "0.875rem",
        textTransform: "none",
        backgroundColor: "transparent",
        color: "#1976d2",
        border: "none",
        outline: "none",
        transition: "none", // **Disable all transition animations**
        animation: "none",  // **Disable any pre-set animations**
        boxShadow: "none",  // **Ensure no shadow animations**
        "&:hover": {
          backgroundColor: "none",
          textDecoration: "none",
          transition: "none", // **Disable hover animation**
        },
        "&:active": {
          backgroundColor: "transparent",
          boxShadow: "none",  // **Remove click effect**
          transform: "none",  // **Prevent shrinking animation**
          transition: "none", // **No transition effect on click**
        },
        "&:focus": {
          outline: "none",
          boxShadow: "none",
          backgroundColor: "transparent",
        },
}));

export function PopupButton({ popupText }) {
  const [openPopup, setOpenPopup] = useState(false);

  return (
  <>
  <PopupButtonTheme onClick={() => setOpenPopup(true)}>{popupText}</PopupButtonTheme>
  <OTPVerificationModal open={openPopup} onClose={() => setOpenPopup(false)}/>   
   </>
);

}

export default { RegisterButton, PopupButton }; 