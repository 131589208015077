import React from "react";
import { Box, Typography, Container } from "@mui/material";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        mt: "auto", // Pushes footer to the bottom when content is small
        py: 1,
        bgcolor: "#fff",
        color: "rgba(0, 0, 0, 0.87)",
        textAlign: "center",
        boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.05)",
      }}
    >
      <Container>
        <Typography variant="body2">
          © {new Date().getFullYear()} Interns IT Solutions. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
